import MarketComponent from "components/cong-dong/marketComponent";
import isEmpty from "lodash/isEmpty";

export default function Markets({ title, items, slug='cho-do-cu', isSearchPage = false }) {
    if (isEmpty(items)) return <></>;
    const genProduct = items.map((itemChild,index) =>{
        return(
            <MarketComponent key={index} item={itemChild} index={index} slug={slug}/>
        );
    });
    return (
        <div className="bb-sp market_old">
            <div className="groupCategory-title">
                <h2 className="groupCategory-tlt">{title}</h2>
                {!isSearchPage && <a href={`/${slug}`} className="readMore">Xem tất cả</a>}
            </div>
            <div className="oldThing">
                <ul>
                    {genProduct}
                </ul>
            </div>
        </div>
    );
}