/* eslint-disable @next/next/no-img-element */
import { getCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG } from "framework/store/constants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { toast } from "react-toastify";
import http from "utilities/http";
import Link from 'next/link';
import { MEMBERSHIP_IMAGE } from "utilities/constants";

export default function ImageComponent({ item, isMember = false}) {
    const [currentFollow, setCurrentFollow] = useState(null);

    useEffect(() => {
        setCurrentFollow(item.is_followed);
    }, [item]);

    const toggleFollow = async (id) => {
        if (isEmpty(getCookie(TOKEN_CONGDONG))) {
            toast.info('Bạn cần đăng nhập để thao tác.');
            return;
        }
        setCurrentFollow(!currentFollow);

        const res = await http(`community/professional/follows/toggle`, {}, {
            body: { id: id },
            method: 'POST',
            token: "Bearer " + getCookie(TOKEN_CONGDONG)
        }, 'v2');
    };

    const genMemberShip = (item) => {
        let classNameByLevel = 'btn--blueBold';
        let levelImage = MEMBERSHIP_IMAGE[0];
        let levelName = item?.name || "Chim non";

        if (item?.id == 2) {
            classNameByLevel = 'btn--red';
            levelImage = MEMBERSHIP_IMAGE[1];
        }

        if (item?.id == 3) {
            classNameByLevel = 'btn--yellow';
            levelImage = MEMBERSHIP_IMAGE[2];
        }

        return (
            <div className="text-center new-membership">
                <span className={"btn member-level " + classNameByLevel}>
                    <img src={levelImage} alt="" style={(item?.id == 1 || !item?.id) ? { paddingBottom: 3 } : {}}/>
                    {" " + levelName}
                </span>
            </div>
        );
    };

    const showroomJob = (!isEmpty(item?.field) ? item?.field?.name : '')
    + (!isEmpty(item?.job_title && item?.job_title?.name != 'Khác') ? (' -  ' + item?.job_title?.name) : '');
    const linkToInfo = "/thanh-vien/u/" + item?.id + "?t=tong-quan";

    return (
        <div className="items new-pro" style={currentFollow ? { borderColor: '#34BEEF' } : {}}>
            <Link href={linkToInfo}><a>
                <div className="user-info-thumb">
                    <img layout="fill" className="new-avatar" src={item?.avatar} alt="" />
                    {item?.rating !== 0 && <div className="rate-star">{item?.rating}
                        <img src="/images/icon-star.svg" align="" alt="" />
                    </div>
                    }
                    {genMemberShip(item?.membership_level?.data)}
                </div>
            </a></Link>

            <Link href={linkToInfo} passHref={true}>
                <div className="user-info">
                    <div className="user-name">
                        <span style={{ cursor: "pointer" }} className="name">{item?.display_name}</span>
                        {item?.is_professional ? <span className="user-stt">Pro</span> : <></>}
                    </div>
                    {!isMember && <p className="text text-center" style={{ height: 20 }}>{showroomJob}</p>}
                </div>
            </Link>
            <button className="btn-flow" onClick={() => (toggleFollow(item?.id))} style={currentFollow ? { backgroundColor: '#34BEEF', color: '#fff' } : {}}>
                {currentFollow ? "" : <Image layout="fill" src="/images/icon-add-user.svg" align="" alt="" />}
                {currentFollow ? "Đang theo dõi" : "Theo dõi"}
            </button>
        </div>
    );
}