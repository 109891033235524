/* eslint-disable @next/next/no-img-element */
import { getCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG } from "framework/store/constants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
// import { Image } from "react-bootstrap";
import Image from 'next/image';
import { toast } from "react-toastify";
import http from "utilities/http";
import { numberWithCommas, changeMonthFirst } from "utilities/helper";
import Share from "components/share";
import dayjs from "dayjs";
import { getThumbnail } from 'components/cong-dong/articleComponent';

export default function ImageComponent({ item, index, slug = "cho-do-cu" }) {

    const [currentLike, setCurrentLike] = useState(null);
    const [currentPin, setCurrentPin] = useState(null);
    const [numberLike, setNumberLike] = useState(null);
    const [numberPin, setNumberPin] = useState(null);

    const [onShare, setOnShare] = useState(false);

    useEffect(() => {
        setCurrentLike(item.current_user_liked);
        setCurrentPin(item.current_user_pinned);
        setNumberLike(item?.interaction_count?.like_count_number);
        setNumberPin(item?.interaction_count?.pin_count_number);
    }, [item]);

    const actionLike = async (postType, id, act) => {
        if (isEmpty(getCookie(TOKEN_CONGDONG))) {
            toast.info('Bạn cần đăng nhập để thao tác.');
            return;
        }
        if (act === 'likes') {
            if (currentLike) setNumberLike(numberLike - 1);
            if (!currentLike) setNumberLike(numberLike + 1);
            setCurrentLike(!currentLike);
        };
        if (act === 'pins') {
            if (currentPin) setNumberPin(numberPin - 1);
            if (!currentPin) setNumberPin(numberPin + 1);

            setCurrentPin(!currentPin);
        };

        const res = await http(`community/${postType}/${act}/toggle`, {}, {
            body: { id: id },
            method: 'POST',
            token: "Bearer " + getCookie(TOKEN_CONGDONG)
        }, 'v2');
    };

    const actionTool = <ul className="oldThing-tools">
        <li>
            <span>
                <img alt="" width={23} height={23} src={currentLike ? "/images/icon-heart.svg" : "/images/icon-heart01.svg"}
                    onClick={() => actionLike(item?.post_type, item?.id, 'likes')} />
            </span>
            {numberLike}
        </li>
        <li>
            <span>
                <a href={(item.status_code == 0 || item.status_code == 2) + `/${slug}/` + item.id + '/' + item.slug + '#comments'}>
                    <img src="/images/icon-comment.svg" alt="" />
                </a>
            </span>
            {item?.interaction_count?.comment_count_number}
        </li>
        <li>
            <span>
                <img src="/images/icon-share01.svg" alt="" onClick={() => { setOnShare(!onShare); }} />
            </span>
            {item?.interaction_count?.post_count_number}
        </li>
    </ul>;

    const thumbnail = getThumbnail(item);
    const isNew = dayjs().diff(dayjs(changeMonthFirst(item.published_at_detail_formatted)), 'day') < 2;

    const link = ((item.status_code == 0 || item.status_code == 2) ? '/preview' : '') + `/${slug}/${item.id}/${item.slug}`;

    return (
        <li key={index}>
            <div className="oldThing-info">
                <div className="oldThing-thumb wrap-next-image">
                    <a href={link}>
                        <Image layout="fill" src={thumbnail} alt="" />
                    </a>
                </div>
                <div className="oldThing-content">
                    <h3 className="oldThing-title">
                        <a href={link}>
                            {item?.title}
                        </a></h3>
                    <ul className="photoStore-item">
                        <li key={index}>{item.first_term?.name}</li>
                    </ul>
                    {actionTool}
                </div>
            </div>
            <Share onShare={onShare} setOnShare={setOnShare} url={`/${slug}/` + item.id + '/' + item.slug} />
        </li>
    );
}