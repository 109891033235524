import { isEmpty } from "lodash";
import ImageComponent from "components/cong-dong/imageComponent";
import { getTypeArticle } from 'utilities/helper';

export default function ImageStore({ title = "", item, slug = 'album', isUserPage = false, isWatchMost = false, isSearchPage = false, perRow = 3, size = null }) {
    if (size) {
        item = (item || []).slice(size);
    }
    const genImage = (item || []).map((item, index) => {
        const slug = getTypeArticle(item);
        return (
            <ImageComponent key={index} item={item} index={index < 3 ? index : null} isWatchMost={isWatchMost} slug={slug} />
        );
    });
    return (
        <div className={"bb-sp gallery_wrap"}>
            {
                title && <div className="groupCategory-title mb-0">
                    <h2 className="groupCategory-tlt">{title}</h2>
                    {
                        (!isSearchPage && !isWatchMost && !isEmpty(item)) && <a href={!isUserPage ? `/${slug}` : `/tai-khoan/${item[0]?.user?.id}/albums`} className="readMore">Xem tất cả</a>
                    }
                </div>
            }

            <div className={"lstPhoto " + (perRow == 2 ? "lstPhoto-2" : "")}>
                <ul className={isWatchMost ? "watch-most" : ""}>
                    {genImage}
                </ul>
            </div>
        </div>
    );
};