import { isEmpty } from "lodash";
import ArticleComponent from 'components/cong-dong/articleComponent';

export default function HomeStory({ title = "", author = true, items, item, slug = 'chuyen-nha', isUserPage = false, isSearchPage = false, forceChuyenNha = false, perRow = 3, isSiteMini = false, slugSiteMini="" }) {
    items = items || item;
    return (
        <div className="bb-sp home-story">
            {
                title && <div className="groupCategory-title mb-0">
                    <h2 className="groupCategory-tlt">{title}</h2>
                    {(!isEmpty(items) && !isSearchPage) && <a href={!isUserPage ? `/${slug}` : `/tai-khoan/${items[0]?.user?.id}/articles`} className={`readMore ${isSiteMini?'readMoreSite':''}`}>Xem tất cả</a>}
                </div>
            }
            <div key={slug + title} className={"lstPhoto " + (perRow == 2 ? "lstPhoto-2" : "")}>
                {!isEmpty(items) && <ul>
                    {items.map((itemChild, index) => {
                        if (forceChuyenNha) itemChild.post_type_id = 2;
                        return (
                            <ArticleComponent key={index} item={itemChild} index={index} slug={slug} isUserPage={isUserPage} author={author} perRow={perRow}></ArticleComponent>
                        );
                    })}
                </ul>}
                {isEmpty(items) && <h4 className="mt-3"> Chưa có bài viết</h4>}
            </div>
        </div>
    );
}
